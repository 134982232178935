import 'jquery'
import 'slick-carousel/slick/slick.min'

jQuery(document).ready(function($) {
  if ($('.is-desktop').length) {
    let $appendClassTo = $('.responsup');
    let $offsetObj = $('#featured-area');
    let $topOffset = $offsetObj.length ? $offsetObj.outerHeight() : window.innerHeight * 0.3;
  
    $(window).on('scroll', function () {
      let $windowpos = $(window).scrollTop();
  
      if ($windowpos >= $topOffset) {
        if (!$offsetObj.hasClass('sticky')) {
          $appendClassTo.addClass("sticky");
        }
      } else {
        $appendClassTo.removeClass("sticky");
      }
    });
  }

  $('.is-mobile .cd-featured-posts').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    draggable: true,
    dots: true,
    arrows: true,
    adaptiveHeight: true,
    prevArrow: '<div class="slick-prev slick-arrow slick-arrow-prev"><i class="fal fa-angle-left"></i></div>',
    nextArrow: '<div class="slick-next slick-arrow slick-arrow-next"><i class="fal fa-angle-right"></i></div>',
    responsive: [{
      breakpoint: 940,
      settings: {
        adaptiveHeight: true,
        arrows: true,
        dots: true,
        slidesToShow: 1,
        centerPadding: '0',
      },
    }],
  }).css({
    'opacity': 1,
  });

  $('#upper-search a, .cd-socials__toggle-search').on('click', function (e) {
		e.preventDefault();
    $('.show-search').fadeIn('fast');
    $('.show-search .search-field').trigger('focus');
  });

  $('.search-menu-close').on('click', function (e) {
		e.preventDefault();
    $('.show-search').fadeOut('fast');
  });

  if ($('.home').length) {
    let home_pagination = $('.home .pagination').detach();
    home_pagination.insertBefore('.cd-section-featured-posts.cd-section-explore-categories');
  }

  if($('.post__bottom-nav__comments').length) {
    $('.post__bottom-nav__comments, .social-share a[href*="comment"]').on('click', function(event) {
      event.preventDefault();
      var text = $('.post__bottom-nav__comments span').text();
  
      $('.post__bottom-nav__comments span').text($.trim(text) === 'Join the Conversation' ? 'Leave the Conversation' : 'Join the Conversation');
      $('.comments').stop(true, true).slideToggle(300);
      $('.comments, .comments-area').toggleClass('comments--active');
      $('.post__bottom-nav').toggleClass('post__bottom-nav--active');
    });
  
    if (location.hash.indexOf('comment') !== -1) {
      $('.post__bottom-nav__comments').trigger('click');
      $('html, body').animate({
        scrollTop: $(location.hash).offset().top - 88,
      });
    }
  }

  $(".cd-column-slider").owlCarousel({
		items:1,
		itemsDesktop:[1199,1],
		itemsDesktopSmall:[980,1],
		itemsTablet:[768,1],
		itemsTabletSmall:[568,1],
		itemsMobile:[479,1],
		pagination:false,
		navigation:true,
		navigationText : ["<i class='fa fa-caret-left'></i> Previous","Next <i class='fa fa-caret-right'></i>"],
	});

  /**
   * Toogle Button
   */
  $('.mobile__menu-toggle').on('click', function(){
    $('.mobile__menu-nav-container').stop(true, true).slideToggle(300);
    $(this).toggleClass('oppened');
  });

  $('.mobile__menu-nav-container .menu-item-has-children').prepend('<span class="mobile__menu-subnav-toggle"><i class="fal fa-angle-right"></i></span>');
  $('.mobile__menu-subnav-toggle').on('click', function() {
    let $this = $(this);
    $this.siblings('.sub-menu').stop(true, true).slideToggle(300)
    $this.closest('li').toggleClass('oppened');
  });

  /**
   * Header Search
   */
  $('.cd-socials__toggle-search').on('click', function(e){
    e.preventDefault();
    var $this = $(this);
    var $form = $this.next();

    if ($('.header-search-form').length === 0) {
      $form = $form.detach();
      $form.addClass('header-search-form').appendTo('body');
      $('.header-search-form').append('<a href="#" class="search-form-close"><i class="fa fa-times"></i></a>');
    }
    $('body > .header-search-form').fadeIn(300).find('.search-form-input').trigger('focus');
    return false;
  });

  $(document).on('click', '.search-form-close', function(e){
    e.preventDefault();
    $('.header-search-form').fadeOut(300);
    return false;
  });
});

(function($){
  /**
   * Add socials to menu
   *
   * To the JS function below work you need to add
   * the rest API below and our social customizer
      add_action( 'rest_api_init', function () {
      register_rest_route( 'chloedigital/v1', '/socials', array(
        'methods' => 'GET',
        'callback' => function() {
          $socials = get_theme_mod('cd_social_list');

          if($socials) {
            return wp_send_json( $socials, 200);
          }

          return wp_send_json(["error" => "Social networks not found."], 404);
        },
      ));
    });
    $.ajax("<?=$home_url; ?>wp-json/chloedigital/v1/socials")
    .done(function(data, response) {
      if(response === 'success') {
        socialHTML = '<ul class="mobile__menu-socials">';
        Object.keys(data).forEach(social => {
          let icon = social+' fab';
          if (social === 'contact') icon = 'envelope far';
          if (social === 'bloglovin') icon = 'heart fas';
          if (social === 'rss') icon = 'rss fas';

          socialHTML += `<a href="${data[social]}" target='_blank' rel='social'><i class='fa-${icon}'></i></a>`;
        });
        socialHTML += '</ul>';

        $('.mobile__menu-nav-container').append(socialHTML);
      }
    })
    .fail(function(error, response) {
      console.error("chloédigital menu - " + error.responseJSON.error);
    });
    */
}(jQuery))